import React, { Component } from "react"
import { Carousel } from "react-responsive-carousel";
import { Button,Card,CardHeader,CardBody } from "reactstrap";
import PopUpModal from "./ModalComponent";
import { reviews } from "../data/reviews";


class Home extends Component{
    constructor(props){
        super(props);
        this.state={
            winWidth: window.innerWidth,
            show: false,
            card1: "display-block",
            card1_btn: "fa fa-caret-down",
            card2: "display-block",
            card2_btn: "fa fa-caret-down"
        }
        this.renderImages=this.renderImages.bind(this);
        this.render_section_4_8=this.render_section_4_8.bind(this);
        this.modalPopUp = this.modalPopUp.bind(this);
    }
    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this))
        this.resize();
        if(this.state.winWidth > 576){
            this.setState({
                card1: "display-block",
                card2: "display-block"
            })
        }
        else{
            this.setState({
                card1:"display-none",
                card2:"display-none"
            })
        }
        setTimeout(this.modalPopUp,10000);
    }
    modalPopUp(){
        if(this.props.check === false){
            this.setState({
                show: true
            })
        }
    }
    resize() {
        this.setState({winWidth: window.innerWidth})
    }
    getSize(value){
        if(value<=600){
            return 100
        }
        else if(value<=1000){
            return 50
        }
        else{
            return 33
        }
    }
    renderImages(data){ 
        const test = data.map((data)=>{
            return(
                <div key={data.id}>
                    <img className="section-9-img" src={data.image} />
                </div>
            );
        })
        return(
            <div>
                <Carousel
                    centerMode
                    showStatus={false}
                    dynamicHeight={false}
                    emulateTouch
                    swipeScrollTolerance={50}
                    centerSlidePercentage={100}
                    showThumbs={false}
                    infiniteLoop
                    showIndicators
                >
                {test}
                </Carousel>
            </div>
        );
    }
    render_reviews(){
        const rendercard= reviews.map((data)=>{
            return(
                <Card className="section-11-card">
                    <CardBody>
                        <div className="row">
                            <div className="col-12 section-11-crd-div">
                                <img className="section-11-crd-img" src={data.img} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <p className="section-11-txt-hd">{data.description}</p>
                                <p>{data.rev}</p>
                                <p className="section-3-txt-2 fl-right">--{data.name}</p>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            );
        })
        return(
            <div>
                <Carousel
                    centerMode
                    showStatus={false}
                    dynamicHeight={false}
                    emulateTouch
                    swipeScrollTolerance={50}
                    centerSlidePercentage={this.getSize(this.state.winWidth)}
                    showThumbs={false}
                    infiniteLoop
                    showIndicators
                    autoPlay={true}
                    interval={2000}
                    swipeable
                >
                    {rendercard}
                </Carousel>
            </div>
        );
    }
    render_section_3(data){
        const returncard= data.map((data)=>{
            return(
                <div key={data.id} className="section-3-div">
                    <img className="section-3-img-div" src={data.image} />
                </div>
            );
        })
        return(
            <div>
                <Carousel
                    centerMode
                    showStatus={false}
                    dynamicHeight={false}
                    emulateTouch
                    swipeScrollTolerance={50}
                    centerSlidePercentage={this.getSize(this.state.winWidth)}
                    showThumbs={false}
                    infiniteLoop
                    showIndicators
                    autoPlay={true}
                    interval={1500}
                    swipeable
                >
                    {returncard}
                </Carousel>
            </div>
        );
    }
    render_section_4_8(data){
        const returncard= data.map((data)=>{
            return(
                <div key={data.id} className="section-3-div">
                    <img className="section-3-img-div" src={data.image} />
                </div>
            );
        })
        return(
            <div>
                <Carousel
                    centerMode
                    showStatus={false}
                    dynamicHeight={false}
                    emulateTouch
                    swipeScrollTolerance={50}
                    centerSlidePercentage={this.getSize(this.state.winWidth)}
                    showThumbs={false}
                    infiniteLoop
                    showIndicators
                    swipeable
                >
                    {returncard}
                </Carousel>
            </div>
        );
    }
    render(){
        const section_3=
            [
                {
                    image: 'images/section_3/img1.1.jpg',
                    id:0
                },
                {
                    image: 'images/section_3/img1.2.jpg',
                    id:1
                },
                {
                    image: 'images/section_3/img1.3.jpg',
                    id:2
                },
                {
                    image: 'images/section_3/img1.4.jpg',
                    id:3
                },
                {
                    image: 'images/section_3/img1.5.jpg',
                    id:4
                }
            ]
        const section_4=[
            {
                id:0,
                image:"images/section_4/img1.1.jpg"
            },
            {
                id:1,
                image:"images/section_4/img1.2.jpg"
            },
            {
                id:2,
                image:"images/section_4/img1.3.jpg"
            }
        ]
        const section_5=[
            {
                id:0,
                image:"images/section_5/img1.1.jpg"
            },
            {
                id:1,
                image:"images/section_5/img1.2.jpg"
            },
            {
                id:2,
                image:"images/section_5/img1.3.jpg"
            }
        ]
        const section_6=[
            {
                id:0,
                image: "images/section_6/img1.1.jpg"
            },
            {
                id:2,
                image: "images/section_6/img1.3.jpg"
            },
            {
                id:3,
                image: "images/section_6/img1.4.jpg"
            }
        ]
        const section_8=[
            {
                id:0,
                image: "images/section_7/img1.1.jpg"
            },
            {
                id:1,
                image: "images/section_7/img1.2.jpg"
            },
            {
                id:2,
                image: "images/section_7/img1.3.jpg"
            },
            {
                id:3,
                image: "images/section_7/img1.4.jpg"
            },
            {
                id:4,
                image: "images/section_7/img1.5.jpg"
            }
        ]
        const section_7=[
            {
                id:0,
                image: "images/section_8/img1.1.jpg"
            },
            {
                id:1,
                image: "images/section_8/img1.2.jpg"
            },
            {
                id:2,
                image: "images/section_8/img1.3.jpg"
            }
        ]
        const section_9_1=[
            {
                image:"/images/section_9/img1.1.png",
                id:0
            },
            {
                image:"/images/section_9/img1.2.png",
                id:1
            },
            {
                image:"/images/section_9/img1.3.png",
                id:2
            },
            {
                image:"/images/section_9/img1.4.png",
                id:3
            }
        ]
        const section_9_2=[
            {
                image:"/images/section_9/img2.1.png",
                id:0
            },
            {
                image:"/images/section_9/img2.2.png",
                id:1
            },
            {
                image:"/images/section_9/img2.3.png",
                id:2
            },
            {
                image:"/images/section_9/img2.4.png",
                id:3
            }
        ]
        
        const onModalclick=()=>{
            this.setState({
                show:true
            })
        }
        const closeModal=() =>{
            this.setState({
                show: false
            })
        }
        const onCard1Click=()=>{
            if(this.state.card1 === "display-none"){
                this.setState({
                    card1_btn: "fa fa-caret-up",
                    card1: "display-block"
                })
            }
            else{
                this.setState({
                    card1_btn: "fa fa-caret-down",
                    card1: "display-none"
                })
            }
        }
        const onCard2Click=()=>{
            if(this.state.card2 === "display-none"){
                this.setState({
                    card2_btn: "fa fa-caret-up",
                    card2: "display-block"
                })
            }
            else{
                this.setState({
                    card2_btn: "fa fa-caret-down",
                    card2: "display-none"
                })
            }
        }
        return(
            <div className="mt-home">
                
                <PopUpModal show={this.state.show} close={closeModal} />
                {/* Section 1 */}
                <div className="main-div">
                    <Carousel
                    centerMode
                    showStatus={false}
                    dynamicHeight={false}
                    emulateTouch
                    swipeScrollTolerance={50}
                    centerSlidePercentage={100}
                    showThumbs={false}
                    infiniteLoop
                    showIndicators
                    >
                        <div>
                            <img className="main-img" src="images/main_slideshow/img1.1.jpg" />
                        </div>
                    </Carousel>
                </div>
                
                {/*Social app link*/}

                <div>
                    <div className="whats-logo-div">
                        <a href="https://wa.me/919341269516/?text=Hello Vcre8 Design Team, I am interested to know more about the interior designs and other services." target="_blank"><img className="social-logo-img" src="/images/whatsapp.png" /></a>
                    </div>
                    <div className="facebook-logo-div">
                        <a href="https://www.facebook.com/vcre8designs?mibextid=LQQJ4d" target="_blank"><img className="social-logo-img" src="/images/facebook.png" /></a>
                    </div>
                    <div className="instagram-logo-div">
                        <a href="https://instagram.com/vcre8designs?igshid=YmMyMTA2M2Y=" target="_blank"><img className="social-logo-img" src="/images/instagram.png" /></a>
                    </div>
                </div>
                

                {/* Section 2 */}

                <div className="container">
                    <div className="section-3-1-header center mt-3"><p>Why should you trust us to meet your needs?</p></div>
                    <div className="row mt-4">
                        <div className="col-6 col-sm-3 center">
                            <img className="section-2-img" src="images/second_slide/img1.png" />
                            <p className="section-3-txt-2">30 Days Delivery</p>
                        </div>
                        <div className="col-6 col-sm-3 center">
                            <img className="section-2-img" src="images/second_slide/img2.png" />
                            <p className="section-3-txt-2">Space Optimization</p>
                        </div>
                        <div className="col-6 col-sm-3 center">
                            <img className="section-2-img" src="images/second_slide/img3.png" />
                            <p className="section-3-txt-2">10 Years Warranty</p>
                        </div>
                        <div className="col-6 col-sm-3 center">
                            <img className="section-2-img" src="images/second_slide/img4.png" />
                            <p className="section-3-txt-2">Personalized Designs</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mt-4 mb-4 center">
                            <Button onClick={onModalclick} className="btn-section-2">Speak with one of Our Design Experts</Button>
                        </div>
                    </div>
                </div>
                {/* Section 3 */}

                <div className="mt-2 bg-grey">
                    <div className="container">
                    <div className="row">
                        <div className="col-12 center mt-4">
                            <p className="section-3-1-header">New Trends and Design Solution</p>
                            <p className="section-3-txt-2">20% Extra Space Guaranteed</p>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12">
                            {this.render_section_3(section_3)}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mt-4 mb-4 center">
                            <Button onClick={onModalclick} className="btn-section-2">Connect with our design team today!</Button>
                        </div>
                    </div>
                    </div>
                </div>

                {/* What we offer section-3-0 */}

                <div className="mt-3">
                    <div className="container">
                        <div className="row center">
                            <div className="col-12 col-sm-6 mt-3">
                                <p className="section-3-1-header">Our offerings</p>
                            </div>
                            <div hidden={(window.innerWidth <= 600) ? 'hidden' : ''} className="col-6 mt-3">
                                <Button onClick={onModalclick} className="btn-section-3-0">Get Free Quote</Button>
                            </div>
                        </div>
                        <div className="row row-crd">
                            <div className="col-12 col-sm-6 row-crd-1">
                                <Card className="section-3-0-card">
                                    <CardBody>
                                        <p className="section-3-0-cardhead"><img className="card-img" src="images/second_slide/img4.png"/>Our Services<span hidden={(window.innerWidth <= 600) ? '' : 'hidden'} className="fl-right"><Button className="section-3-0-crdbtn" onClick={onCard1Click}><span className={this.state.card1_btn} /></Button></span></p>
                                        <ul className={this.state.card1}>
                                            <span className={this.state.card1}><li className="section-3-txt-2">Wall design and painting</li></span>
                                            <span className={this.state.card1}><li className="section-3-txt-2">Modular Kitchen designs</li></span>
                                            <span className={this.state.card1}><li className="section-3-txt-2">Bedroom interiors</li></span>
                                            <span className={this.state.card1}><li className="section-3-txt-2">Living room Interiors</li></span>
                                            <span className={this.state.card1}><li className="section-3-txt-2">Dining and Other Interiors</li></span>
                                            <span className={this.state.card1}><li className="section-3-txt-2">End to End service</li></span>
                                            <span className={this.state.card1}><li className="section-3-txt-2">Plumbing</li></span>
                                            <span className={this.state.card1}><li className="section-3-txt-2">Electrical part</li></span>
                                            <span className={this.state.card1}><li className="section-3-txt-2">Civil changes</li></span>
                                        </ul>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="col-12 col-sm-6 row-crd-1">
                                <Card className="section-3-0-card">
                                    <CardBody>
                                        <p className="section-3-0-cardhead"><img className="card-img" src="images/second_slide/img3.png"/>Warranty<span hidden={(window.innerWidth <= 600) ? '' : 'hidden'} className="fl-right"><Button className="section-3-0-crdbtn" onClick={onCard2Click}><span className={this.state.card2_btn} /></Button></span></p>
                                        <ul className={this.state.card2}>
                                            <span className={this.state.card2}><li className="section-3-txt-2">FLAT 10-year warranty¹ - Stay worry-free with our warranty policy on modular products</li></span>
                                            <span className={this.state.card2}><li className="section-3-txt-2">
  Up to 1-year on-site service warranty¹ - Warranty on on-site services such as painting, electrical, plumbing and more.
  </li></span>
                                            
                                        </ul>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                        <div className="row center">
                            <div hidden={(window.innerWidth <= 600) ? '' : 'hidden'} className="col-12 mt-3">
                                <Button onClick={onModalclick} className="btn-section-3-0">Get Free Quote</Button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* How it works section-3.1 */}

                <div className="mb-5 mt-4 section-3-1-bg">
                    <div className="container">
                        <div className="row center">
                            <div className="col-12 section-3-1-header"><p>Our Methodology</p></div>
                        </div>
                        <div className="row center mt-2">
                            <div className="col-12 col-sm-2 section-3-1-div">
                                <div className="row">
                                    <p className="col-12 section-3-txt-2">Step 1</p><br/>
                                    <img className="col-6 col-sm-12 section-3-1-img" src="images/five_steps/img1.png" />
                                    <p className="col-6 col-sm-12 section-3-txt-2 mt-2">Meet our designer</p>
                                </div>
                            </div>
                            <div className="col-12 col-sm-2 section-3-1-div">
                                <div className="row center">
                                    <p className="col-12 section-3-txt-2">Step 2</p><br/>
                                    <img className="col-6 col-sm-12 section-3-1-img" src="images/five_steps/img2.png" />
                                    <p className="col-6 col-sm-12 section-3-txt-2 mt-2">Get free Estimate</p>
                                </div>
                            </div>
                            <div className="col-12 col-sm-2 section-3-1-div">
                                <div className="row center">
                                    <p className="col-12 section-3-txt-2">Step 3</p><br/>
                                    <img className="col-6 col-sm-12 section-3-1-img" src="images/five_steps/img3.png" />
                                    <p className="col-6 col-sm-12 section-3-txt-2 mt-2">Design</p>
                                </div>
                            </div>
                            <div className="col-12 col-sm-2 section-3-1-div">
                                <div className="row center">
                                    <p className="col-12 section-3-txt-2">Step 4</p><br/>
                                    <img className="col-6 col-sm-12 section-3-1-img" src="images/five_steps/img4.png" />
                                    <p className="col-6 col-sm-12 section-3-txt-2 mt-2">Installation</p>
                                </div>
                            </div>
                            <div className="col-12 col-sm-2 section-3-1-div">
                                <div className="row center">
                                    <p className="col-12 section-3-txt-2">Step 5</p><br/>
                                    <img className=" col-6 col-sm-12 section-3-1-img" src="images/five_steps/img5.png" />
                                    <p className="col-6 col-sm-12 section-3-txt-2 mt-2">Move in 45/60/90 Days</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mt-4 mb-4 center">
                                <Button onClick={onModalclick} className="btn-section-2">Connect with our design team today!</Button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Section 4 */}

                <div className="bg-grey">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 center mt-4">
                                <p className="section-3-1-header">Modular Kitchen Designs</p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-12">
                                {this.render_section_4_8(section_4)}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mt-4 mb-4 center">
                                <Button onClick={onModalclick} className="btn-section-2">Schedule a Call Back!</Button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Section 5 */}

                <div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 center mt-4">
                                <p className="section-3-1-header">Bedroom Interiors for Comfort and Style</p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-12">
                                {this.render_section_4_8(section_5)}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mt-4 mb-4 center">
                                <Button onClick={onModalclick} className="btn-section-2">Meet our Designer</Button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Section 6 */}

                <div className="bg-grey">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 center mt-4">
                                <p className="section-3-1-header">Living Room Interiors for Best Impression</p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-12">
                                {this.render_section_4_8(section_6)}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mt-4 mb-4 center">
                                <Button onClick={onModalclick} className="btn-section-2">Book free consultation</Button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Section 7 */}

                <div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 center mt-4">
                                <p className="section-3-1-header">Wall Designs</p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-12">
                                {this.render_section_4_8(section_7)}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mt-4 mb-4 center">
                                <Button  onClick={onModalclick} className="btn-section-2">Book free consultation</Button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Section 8 */}

                <div className="bg-grey">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 center mt-4">
                                <p className="section-3-1-header">Dining and other interiors</p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-12">
                                {this.render_section_3(section_8)}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mt-4 mb-4 center">
                                <Button onClick={onModalclick} className="btn-section-2">Book a virtual tour Today!</Button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Section 9 hidden */}

                <div hidden className="bg-grey mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 center mt-4">
                                <p className="section-3-txt-1">1650+ Successfully Executed Projects</p>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-12 col-sm-6">
                                <div className="row">
                                    <div className="col-3">
                                        <img className="section-9-logo" src="images/section_9/logo1.png" />
                                    </div>
                                    <div className="col-9">
                                        <p className="section-9-txt-1 fl-left">Pradeep Sekaran</p>
                                    </div>
                                </div>
                                <p className="mt-4">Livin interiors definitely need to be appreciated for the service. They have done as per our budget with no hiccups. Having a dedicated team for service. After-sales service was sent for minor repairs. Livin kept their promises. Definitely recommend Livin.</p>
                                <div className="row">
                                    <div className="col-12">
                                        {this.renderImages(section_9_1)}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6">
                                <div className="row">
                                    <div className="col-3">
                                        <img className="section-9-logo" src="images/section_9/logo2.png" />
                                    </div>
                                    <div className="col-9">
                                        <p className="section-9-txt-1 fl-left">Gopinath</p>
                                    </div>
                                </div>
                                <p className="mt-4">Excellent work! We are completely satisfied with the work. The quality of the work is at its best.<br></br>
Thanks for making it happen within a short time span. Really appreciate your patience and dedication. Wishing team LIVIN all the best.</p>
                                <div className="row">
                                    <div className="col-12">
                                        {this.renderImages(section_9_2)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mt-4 mb-4 center">
                                <Button onClick={onModalclick} className="btn-section-2">Book Free Consultation</Button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Section 10 */}
                <div className="mt-4 section-3-1-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-lg-4">
                                <div className="row">
                                    <div className="col-4">
                                        <img className="fl-left" src="images/section_10/img1.png" />
                                    </div>
                                    <div className="col-8"><p className="fl-left section-3-txt-2">Living/Dining Room</p></div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4">
                                <div className="row">
                                    <div className="col-4">
                                        <img className="fl-left" src="images/section_10/img2.png" />
                                    </div>
                                    <div className="col-8"><p className="fl-left section-3-txt-2">Bedroom</p></div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4">
                                <div className="row">
                                    <div className="col-4">
                                        <img className="fl-left" src="images/section_10/img3.png" />
                                    </div>
                                    <div className="col-8"><p className="fl-left section-3-txt-2">Kitchen</p></div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4 mt-4">
                                <div className="row">
                                    <div className="col-4">
                                        <img className="fl-left" src="images/section_10/img4.png" />
                                    </div>
                                    <div className="col-8"><p className="fl-left section-3-txt-2">Renovation and Construction</p></div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4 mt-4">
                                <div className="row">
                                    <div className="col-4">
                                        <img className="fl-left" src="images/section_10/img5.png" />
                                    </div>
                                    <div className="col-8"><p className="fl-left section-3-txt-2">Interior Design Services</p></div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4 mt-4">
                                <div className="row">
                                    <div className="col-4">
                                        <img className="fl-left" src="images/section_10/img6.png" />
                                    </div>
                                    <div className="col-8"><p className="fl-left section-3-txt-2">Home Improvement services</p></div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mt-5 mb-4 center">
                                <Button onClick={onModalclick} className="btn-section-2">Book a session to Discuss your Design Needs</Button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Reviews Section 11 */}

                <div>
                    <div className="container">
                        <div className="row center mt-3 mb-3">
                            <div className="col-12">
                                <p className="section-3-1-header">Here's what our client's have to say</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {this.render_reviews()}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mt-3 mb-2 center">
                                <Button onClick={onModalclick} className="btn-section-2">Book a Free Consultation</Button>
                            </div>
                        </div>
                    </div>
                </div>

                {/*About us */}

                <div className="bg-grey">
                    <div className="container">
                        <div className="row mt-4">
                            <div className="col-12 col-sm-6">
                                <div className="row mt-4">
                                    <div className="col-12"><p className="section-3-1-header">About us</p></div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-12">
                                        <p className="abt-txt-hd">Vcre8 Designs</p>
                                        <p className="section-3-txt-2">Vcre8 Designs is an interior design company based in Bangalore and Lucknow.With an experiences and skilled team of experts, we offer quality services in the area of interior and exterior design, construction and renovation.<br/> Our specialty is that we not only design your house but also create maximum space for utility and make your house look more spacious.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6">
                                <div className="row mt-4">
                                    <div className="col-6 center"><img className="section-abtus-img" src="images/aboutus/img1.jpeg" /></div>
                                    <div className="col-6 center section-3-txt-2"><p>Shilpa Shrivastava</p><p>CEO & Founder</p></div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6 center"><img className="section-abtus-img" src="images/aboutus/img2.jpeg" /></div>
                                    <div className="col-6 center section-3-txt-2"><p>Amit Kumar</p>Founder & Chief Design Officer</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        );
    }
}

export default Home;