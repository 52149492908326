export const reviews =[
    {
        id:0,
        description:"Fantastic",
        rev: "From creating the perfect layout to finding pieces I absolutely loved, my designer really took my space to the next level. I never dreamed my home could look and feel this good!",
        name:"Jan",
        img:"reviews_images/img2.png"
    },
    {
        id:1,
        description:"Amazing Design",
        rev:"Havenly is perfect for me. It’s a great place to start to help narrow down all the choices and get a better sense of what I like and want in my home. And Erin has been so incredibly dedicated and responsive to my project. She wants to make sure I live the finished project!",
        name:"Hun",
        img:"reviews_images/img2.png"
    },
    {
        id:2,
        description:"Just my style",
        rev:"It only took two rounds to completely design my space. Laura had great advice and was able to incorporate all of my feedback.",
        name:"hari",
        img:"reviews_images/img2.png"
    }
]