import React from "react";

function Footer(){
    return(
        <div className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-6">
                        <div className="row">
                            <div className="col-12"><img className="logo fl-left" alt="Vcre8 Designs" src="images/logo.jpg" /></div>
                        </div>
                        
                    </div>
                    <div className="col-12 col-sm-6 text-black">
                        <p className="section-3-1-header ">Contact Us</p>
                        <address className="section-3-txt-2">
                            Sobha Aquamarine,
                            Bellandur,<br />
                            Bangalore
                        </address>
                        <address className="section-3-txt-2">
                            Head office:  Janakipuram,
                            Lucknow,<br/>
                            Uttar Pradesh
                        </address>
                        <i className="fa fa-phone fa-lg section-3-txt-2"></i>: +91 9341269516<br />
		                <i className="fa fa-fax fa-lg section-3-txt-2"></i>: +91 9341269516<br />
		                <i className="fa fa-envelope fa-lg section-3-txt-2"> vcre8@gmail.com</i>
                    </div>
                </div>
                <div className="row justify-content-center mt-3">             
                <div className="col-auto text-black">
                    <p className="section-3-txt-2">© Copyright 2022 Vcre8 Designs, All rights reserved</p>
                </div>
            </div>
            </div>
        </div>
    );
}

export default Footer;