import React,{Component} from "react";
import {Navbar,NavbarBrand,Button} from 'reactstrap'
import PopUpModal from "./ModalComponent";
import Home from "./HomeComponent";

class Header extends Component{
    constructor(props){
        super(props);
        this.state={
            winWidth: window.innerWidth,
            show: false,
            checkModal:false
        }
    }
    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this))
        this.resize()
    }

    resize() {
        this.setState({winWidth: window.innerWidth})
    }
    getSize(value){
        if(value<=600){
            return
        }
        else if(value>600){
            return
        }
    }
    render(){
        const handleModal=()=>{
            this.setState({
                show: true,
                checkModal:true
            })
        }
        const closeModal=()=>{
            this.setState({
                show: false
            })
        }
        return(
            <div>
            <div hidden>
                <Home show={this.state.show} check={this.state.checkModal} />
            </div>
            <PopUpModal close={closeModal} show={this.state.show} />
            <Navbar fixed="top" className="mt-head">
                <div className="container">
                    <div className="row">
                        <div className="col-6 col-sm-4 col-lg-8">
                            <NavbarBrand href="#"><img alt="Vcre8 Designs" src="images/logo.jpg" className="logo fl-left"></img></NavbarBrand>
                        </div>
                        <div hidden={(window.innerWidth <= 600) ? 'hidden' : ''} className="col-sm-4 col-lg-2">
                            <a className="btn-ph" href="tel:+91 9341269516"><span className="fa fa-phone">+91 9341269516</span></a>
                        </div>
                        <div className="col-6 col-sm-4 col-lg-2">
                            <Button onClick={handleModal} className="btn-nav">Get Free Quote</Button>
                        </div>
                    </div>
                </div>
            </Navbar>
            </div>
        );
    }
}

export default Header;