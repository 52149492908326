import React,{useState} from "react";
import { Button,Form,FormGroup, Row,Col,Label, Input, FormFeedback} from "reactstrap";
import Modal from 'react-bootstrap/Modal'
import axios from 'axios'
import {NotificationContainer, NotificationManager} from 'react-notifications';


const isNumber = (val) => !isNaN(Number(val));
const validEmail = (val) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val);

function PopUpModal(props){
    const [username,setusername] = useState("");
    const [email, setemail] = useState("");
    const [phonenum,setphonenum] = useState("");
    const [prpname,setprpname] = useState("");
    const [invalidphone,setinvalidphone] =useState(false);
    const [invalidemail,setinvalidemail] = useState(false);
    const [validphone,setvalidphone] = useState(false);
    const [validemail,setvalidemail] = useState(false);

    const handleClose=()=>{
        setvalidemail(false);
        setinvalidemail(false);
        setvalidphone(false);
        setinvalidphone(false);
        props.close();
    }
    const onPhoneChange=(e) =>{
        setphonenum(e.target.value);
        if(e.target.value.length <10 || e.target.value.length >10 || !isNumber(e.target.value)){
            setinvalidphone(true);
            setvalidphone(false);
        }
        else{
            setinvalidphone(false);
            setvalidphone(true);
        }
    }
    const onEmailChange=(e)=>{
        setemail(e.target.value);
        if(!validEmail(e.target.value)){
            setinvalidemail(true);
            setvalidemail(false);
        }
        else{
            setinvalidemail(false);
            setvalidemail(true);
        }
    }
    const handleSubmit=(event)=>{
        if(phonenum.length != 10 || !isNumber(phonenum)){
            setinvalidphone(true);
        }
        if(!validEmail(email)){
            setinvalidemail(true);
        }
        if(phonenum.length === 10 && validEmail(email) && isNumber(phonenum)){
            setvalidemail(false);
            setinvalidemail(false);
            setvalidphone(false);
            setinvalidphone(false);
            axios.post('http://15.207.107.128:6300/public/contactUs',
            {
                Name:username,
                Mobile:phonenum,
                Email:email,
                ProperyDetail:prpname
            })
            .then((res)=>{
                if(res.status===200){
                    NotificationManager.success(res.data.Message);
                }
                else{
                    NotificationManager.warning("Error ",res.status,": ",res.statusText);
                }
            })
            .catch((error)=>{
                NotificationManager.error(error.message);                
            })
            props.close();
        }
        event.preventDefault();
    }
    return(
        <div>
        <NotificationContainer />
        <Modal onHide={handleClose} show={props.show}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className="modal-header">
                <p className="modal-header-txt">Free Consultation</p>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <Form>
                    <FormGroup>
                    <Row>
                        <Col md={3}>
                            <Label htmlFor="name">Name</Label>
                        </Col>
                        <Col md={9}>
                        <Input invalid={false} onChange={(e)=> setusername(e.target.value)} type="text" id="name" name="name" placeholder="Name"
                        />
                        <FormFeedback invalid>Invalid Input</FormFeedback>
                        </Col>
                    </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col md={3}>
                            <Label htmlFor="phonenum">Phone Number</Label>
                            </Col>
                            <Col md={9}>
                            <Input valid={validphone} invalid={invalidphone} onChange={(e)=> onPhoneChange(e)} type="text" id="phonenum" name="phonenum" placeholder="Phone Number"
                            />
                            <FormFeedback invalid >Enter Valid phone number</FormFeedback>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col md={3}>
                            <Label htmlFor="email">Email</Label>
                            </Col>
                            <Col md={9}>
                            <Input valid={validemail} invalid={invalidemail} onChange={(e)=> onEmailChange(e)} type="text" id="email" name="email" placeholder="Email"
                            />
                            <FormFeedback invalid>Enter Valid Email</FormFeedback>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                        <Col md={3}>
                        <Label htmlFor="prpname">Property Name</Label>
                        </Col>
                        <Col md={9}>
                        <Input invalid={false} onChange={(e)=> setprpname(e.target.value)} type="text" id="prpname" name="prpname" placeholder="Property Name"
                        />
                        <FormFeedback>Invalid Input</FormFeedback>
                        </Col>
                        </Row>
                    </FormGroup>
                </Form>
            </Modal.Body>
            <Modal.Footer className="center">
                <Button className="modal-btn" onClick={handleSubmit} value="submit" typeof="submit">Get free Quote</Button>
            </Modal.Footer>
        </Modal>
        </div>
    );
}

export default PopUpModal;